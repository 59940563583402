/* eslint-disable */

declare var VERSION: string
declare var KEY: string
declare var URL: string
declare var DEBUG: boolean
import {loadOnce, nextTick} from './utils'
import { TrackerInput, GA, TrackerArgs, EventTypes, TrackerOnFunction, Tracker, PluginFunction } from './types'
/* eslint-enable */

const ATTR = 'data-core-analytics'

export default create() // Export isolated tracker instance, if consumed from npm

// Factory pattern
function create (): Tracker {
  const queue: Array<(...args: any[]) => any> & { index?: number } = [] // Array of queued functions
  const stack: Array<any> = [] // Currently executing action objects
  const store = Object.create(null)
  const events: Record<EventTypes, TrackerOnFunction[]> = Object.create(null)
  const loaded = Object.create(null)
  let initialized = false

  // type guards
  const isGA = (type?: TrackerInput): type is GA => (type as GA)?.q !== undefined
  const isFunction = (type?: TrackerInput): type is () => any => typeof (type as () => any) === 'function'

  const tracker: Tracker = (type?: TrackerInput, args?: TrackerArgs): Tracker => {
    if (isGA(type)) { // an existing tracker/queue is pushed, run it
      // @ts-ignore
      type.q.forEach((action) => tracker(...action)) // ...and run after creation
    } else if (isFunction(type)) {
      const actionIndex = queue.index! > 1 ? 1 - queue.index! : queue.length // Never queue a 0 unless queue is empty
      queue.splice(actionIndex, 0, type) // Queue after active or at end
      if (initialized) nextTick(() => queue.index || tracker.next()) // Run when idle and all pushing is done
    } else if (typeof type === 'string') {
      const actionCompleted = () => stack.pop() // Remove action from stack when it's done
      const actionFunction = () => {
        const handlers = events[type] || []
        if (!handlers.length) tracker.log(`Missing handler for action ${type}`)
        stack.push({ type, args })
        handlers.forEach((fn) => fn(args!, tracker, type))
      }

      if (type === 'initialize') {
        if (initialized) return tracker('error', new Error('Tracker is already initialized'))
        initialized = true
        queue.unshift(actionFunction) // Do not remove initialize from stack, so all later bound .on('initialize') is run instantly
        nextTick(tracker.next) // Manually kickstart when idle
      } else {
        tracker(actionFunction)
        tracker(actionCompleted)
      }
    }
    return tracker // make chainable
  }

  tracker.version = VERSION // For simpler debugging
  tracker.create = create // Expose API for creating new trackers
  tracker.debug = !!DEBUG

  tracker.set = (key: { [key: string]: any } | string, val?: any): void => {
    if (typeof key === 'object') Object.keys(key).forEach((k) => tracker.set(k, key[k]))
    else store[key] = val
  }

  tracker.get = (key?: string) => key ? store[key] : store

  tracker.on = (type: EventTypes, callback: (...args: any[]) => void): void => {
    (events[type] = events[type] || []).push(callback)
    stack.forEach((item) => item.type === type && callback(item.args, tracker, type)) // If currently processing action, also exec
  }

  tracker.off = (type: EventTypes, callback: (...args: any[]) => void): void => {
    events[type] = (events[type] || []).filter((v) => callback && v !== callback)
  }

  tracker.log = (...args: any[]) => {
    if (tracker.debug) console.log(`%c${tracker!.uuid}:`, 'font-weight:bold', ...args)
  }

  tracker.load = (url: string, path: string) => {
    if (!loaded[path]) {
      loaded[path] = true
      tracker(() => {
        loadOnce({
          src: url,
          onload: (script: { src: string }) => {
            tracker.log(`Loading ${url}`)
            const [functionNamespace, pluginPath] = path.split('.')
            const pluginFunction: PluginFunction | undefined = window[functionNamespace] && window[functionNamespace][pluginPath]
            if (typeof pluginFunction === 'function') {
              pluginFunction(tracker)
            } else tracker('error', new Error(`Expected ${script.src} to expose function at window.${path}`))
            tracker.next()
          }
        })
        return false // Stop queue execution until script has loaded
      })
    }
  }

  tracker.next = () => {
    const hasQueue = queue.index = queue.length // Store where we are in the queue
    const fn = queue.shift()
    const result = hasQueue && fn!(tracker)

    if (typeof result === 'object' && result !== null && 'then' in result && typeof result.then === 'function') result.then(tracker.next) // Promise support
    else if (hasQueue && result !== false) tracker.next() // Automatically proceed unless action asks to be async
  }

  tracker.contains = (element: HTMLElement) => {
    for (let el = element; el; el = el.parentElement!) {
      if (el.hasAttribute(ATTR)) return el.hasAttribute(tracker.attr!) && element
    }
    return false
  }

  tracker.uuid = ''
  tracker.attr = ''

  // Register dependencies
  tracker.on('error', (error) => console.error(`%c${tracker.uuid || 'Core Analytics'}:`, 'font-weight:bold', error.message))
  tracker.on('context', (action) => ('userId' in action) && tracker.set('userId', action.userId))
  tracker.on('pageview', (action = {}) => {
    if (action.tnsSection) {
      tracker.load(`${URL}/statistics/unispring.min.js`, `${KEY}.statistics/unispring`)
      tracker.load(`${URL}/statistics/tns.min.js`, `${KEY}.statistics/tns`)
    }
  })
  tracker.on('recommendations', () => tracker.load(`${URL}/recommendations/ecommerce.min.js`, `${KEY}.recommendations/ecommerce`))
  tracker.on('ecLite', () => tracker.load(`${URL}/statistics/ecommerce-lite.min.js`, `${KEY}.statistics/ecommerce-lite`))
  tracker.on('optimize', () => tracker.load(`${URL}/experiments/optimize.min.js`, `${KEY}.experiments/optimize`))
  tracker.on('time-spent', () => tracker.load(`${URL}/engagement/time-spent.min.js`, `${KEY}.engagement/time-spent`))
  tracker.on('content-consumed', () => tracker.load(`${URL}/engagement/content-consumed.min.js`, `${KEY}.engagement/content-consumed`))
  tracker.on('initialize', (action = {}): Tracker | void => {
    if (/\W/.test(action.trackerName || '')) return tracker('error', new Error('trackerName can only contain alphanumeric characters'))
    const container = action.container || document.documentElement

    tracker.uuid = `ca_${action.trackerName || Math.round(Date.now() * Math.random()).toString(32)}`
    tracker.attr = `${ATTR}-${tracker.uuid.replace(/[\W_]+/g, '-')}` // Strip invalid attribute characters
    container.setAttribute(ATTR, '') // Ensure we can scope events within a container
    container.setAttribute(tracker.attr, '') // Ensure a container can have multiple trackers
    tracker.set({
      userId: action.userId,
      parentId: action.parentId,
      clientId: action.clientId
    })

    if (action.debug) tracker.log('Debug:', tracker.debug = Boolean(action.debug))
    if (action.ga) tracker.load(`${URL}/statistics/analytics.min.js`, `${KEY}.statistics/analytics`)
  })

  return tracker
}
